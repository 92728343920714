@import url('https://fonts.googleapis.com/css?family=Poppins:regular,bold,italic,medium,semibold&subset=latin,latin-ext');

body{
  background: url(Assets/background.jpg);
  font-size: 16px;
  font-family: 'Poppins', serif;
}
.ant-result-title{
  color: #F2F3F4;
}
.ant-result-subtitle{
  color: #F8F9F9;
}
.ant-card{
  border-radius: 8px;
  overflow: hidden;
  color: #ffff;
  background: #363636;
  box-shadow: rgb(0 0 0 / 54%) 0px 3px 15px;

}
.ant-card-body{
  background: #363636;
  padding: 56px;
}
.ant-form-item-label > label{
  color:#F8F9F9
}
.ant-radio-wrapper{
  color:#F8F9F9
}
.header img {
  max-width: 100%;
}
.question {
  display: none;
}
.question.active {
  display: block;
}

.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon {
  background: #775CF9;
  width: 42px;
  height: 42px;
  line-height: 42px;
  border-radius: 21px;
  border: 1px solid #ffff;
}

input{
  height: 40px!important;
}
.pin input {
  width: 100%;

}

.countDown {
  font-size: 42px;
  color: #F8F9F9;
}

.ant-btn-primary {
  color: #F8F9F9;
  border-color: #F9AC00;
  background:#F9AC00;
  box-shadow: 0 2px 0 rgb(0 0 0 / 5%);
  font-size: 14px;
  height: 40px;
}
.ant-btn-primary:hover {
  border-color: #F9AC00;
  background: #F9AC00;
}
